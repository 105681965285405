import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { FluidObject } from 'gatsby-image';
import { Element } from 'react-scroll';

import Navigation from 'components/Organisms/Navigation';
import WhatProblem from 'components/Organisms/WhatProblem';
import IntroductionEffect from 'components/Organisms/IntroductionEffect';
import WhyChooseVoda from 'components/Organisms/WhyChooseVoda';
import Footer from 'components/Organisms/Footer';
import MainFunction from 'components/Organisms/Function/MainFunction';
import SiteSlogan from 'components/Organisms/SiteSlogan';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

// const PopupLayout = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 999;
// `;

// const Popup = styled.div`
//   width: 100%;
//   border: 1px solid #ddd;
// `;

// const ClosePopup = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: end;
//   cursor: pointer;
// `;

interface RootTemplateProps {
  logoNerdImg: FluidObject;
  functionData: {
    isDescLeft: boolean;
    descData: {
      title: string;
      desc: string;
      to?: string;
      buttonTitle?: string;
    };
    isScroll: boolean;
    IllustImg: FluidObject;
    isIllustBackground: boolean;
    isBackGround: boolean;
    width: number;
    key: string;
    isAbsolute?: boolean;
  }[];
  lotateImg: FluidObject[];
}

const RootTemplate: FunctionComponent<RootTemplateProps> = ({
  logoNerdImg,
  functionData,
  lotateImg,
}) => {
  // const [openPopup, setOpenPopup] = useState(true);
  return (
    <Component>
      {/* {openPopup ? (
        <PopupLayout>
          <Popup>
            <img
              src="https://mailing.plani.co.kr/VODA/240325_popup.png"
              width="600"
              useMap="#map1"
              alt="VDOA01"
              style={{ display: 'block', border: 0 }}
            />
          </Popup>
          <map name="map1">
            <area
              shape="rect"
              coords="5,543,600,598"
              href="https://mailing.plani.co.kr/VODA/VODA.html"
            />
          </map>
          <ClosePopup onClick={() => setOpenPopup(false)}>
            <P3>닫기 </P3>
            <IconClosePopup />
          </ClosePopup>
        </PopupLayout>
      ) : null} */}
      <Navigation vodaLogoImg={logoNerdImg} />
      <SiteSlogan lotateImg={lotateImg} />
      <Element name="auto_scroll">
        <WhatProblem />
      </Element>
      {functionData.map((element, index) => {
        if (index === 0) {
          return <MainFunction {...element} />;
        }
        return null;
      })}
      {functionData.map((element, index) => {
        if (index !== 0) {
          return <MainFunction {...element} />;
        }
        return null;
      })}
      <IntroductionEffect />
      <WhyChooseVoda />
      <Footer />
    </Component>
  );
};

export default RootTemplate;
