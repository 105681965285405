import React from 'react';
import styled from '@emotion/styled';
import FunctionDesc from 'components/Molecule/FunctionDesc';
import { css } from '@emotion/react';
import ReportIllust from 'components/Organisms/Function/ReportIllust';
import { FluidObject } from 'gatsby-image';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

const Component = styled.div<{
  isDescLeft: boolean;
  isBackGround: boolean;

  isIllustBackground: boolean;
}>`
  width: 100%;

  display: flex;
  justify-content: center;

  ${(props) => {
    if (props.isDescLeft) {
      return css``;
    }
    return css`
      ${MaxWrapper} {
        flex-direction: row-reverse;
      }
    `;
  }}

  ${(props) => {
    if (props.isBackGround) {
      return css`
        background-color: ${props.theme.colors.grey50};
        padding-top: 80px;
        padding-bottom: 80px;
      `;
    }
    return css`
      padding-top: 80px;
      padding-bottom: 80px;
    `;
  }}

  ${(props) => {
    if (props.isIllustBackground) {
      return css`
        padding-top: 40px;
        padding-bottom: 40px;
      `;
    }
    return css``;
  }}
`;

const MaxWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  justify-content: center;
`;

const DescWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IllustWrapper = styled.div<{
  isDescLeft: boolean;
  isBackground: boolean;
  isScroll: boolean;
  width: number;
  detect: boolean;
}>`
  position: static;
  display: flex;
  top: 0px;

  width: ${(props) => props.width}px;

  opacity: 0;
  transform: translate3d(100%, 0, 0);
  transition: all 1s;

  ${(props) => {
    if (props.detect) {
      return css`
        opacity: 1;
        transform: translateZ(0);
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.isScroll) {
      return css`
        height: 100%;
        overflow: scroll;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.isBackground) {
      return css`
        background-color: ${props.theme.colors.purple4};
        padding-top: 20px;
        padding-left: 20px;

        div {
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 0px;
          padding-bottom: 0px;
          padding-right: 0px;
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.isDescLeft) {
      return css`
        margin-left: 80px;
      `;
    }
    return css`
      margin-right: 80px;
    `;
  }}
`;

interface MainFunctionProps {
  isDescLeft: boolean;
  descData: {
    title: string;
    desc: string;
    to?: string;
    buttonTitle?: string;
  };
  IllustImg: FluidObject;
  isIllustBackground: boolean;
  isBackGround: boolean;
  isScroll: boolean;
  width: number;
  isAbsolute?: boolean;
}

const MainFunction = ({
  isDescLeft,
  descData,
  IllustImg,
  isIllustBackground,
  isBackGround,
  isScroll,
  width,
  isAbsolute,
}: MainFunctionProps) => {
  const BlockEl = useRef<HTMLDivElement>(null);
  const illustEl = useRef<HTMLDivElement>(null);
  const ComponentEl = useRef<HTMLDivElement>(null);

  const [illustWidth, setIllustWidth] = useState(0);
  const [illustHeight, setIllustHeight] = useState(0);
  const [isDetect, setIsDetect] = useState(false);

  useEffect(() => {
    const { current } = BlockEl;
    if (current && isDescLeft) {
      const left = window.pageXOffset + current.getBoundingClientRect().left;
      setIllustWidth(document.documentElement.clientWidth - left);
    } else if (current) {
      const right = window.pageXOffset + current.getBoundingClientRect().right;
      setIllustWidth(right);
    }
  }, [BlockEl.current]);

  useEffect(() => {
    const { current } = illustEl;
    if (current) {
      setIllustHeight(current.clientHeight);
    }
  }, [illustEl.current]);

  useEffect(() => {
    const { current } = ComponentEl;
    if (current) {
      const observer = new IntersectionObserver((e) => {
        e.forEach((element) => {
          if (element.isIntersecting) {
            console.log('detecting');
            setIsDetect(true);
          } else {
            setIsDetect(false);
          }
        });
      });
      observer.observe(current);
    }
  }, [ComponentEl]);

  return (
    <Component
      isDescLeft={isDescLeft}
      isBackGround={isBackGround}
      isIllustBackground={isIllustBackground}
    >
      <MaxWrapper>
        <DescWrapper ref={ComponentEl}>
          <FunctionDesc
            title={descData.title}
            desc={descData.desc}
            to={descData.to}
            buttonTitle={descData.buttonTitle}
            isCenter={false}
          />
        </DescWrapper>

        <IllustWrapper
          width={width}
          isScroll={isScroll}
          isDescLeft={isDescLeft}
          isBackground={isIllustBackground}
          ref={illustEl}
          detect={isDetect}
          className="illust-wrapper"
        >
          <ReportIllust
            isRight={isDescLeft}
            img={IllustImg}
            isAbsolute={isAbsolute}
          />
        </IllustWrapper>
      </MaxWrapper>
    </Component>
  );
};

export default MainFunction;
