import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { H1, P1 } from 'components/Atom/Typo';

const Copmponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${H1} {
    font-size: 48px;
    color: ${(props) => props.theme.colors.grey900};
    line-height: 1;

    &:last-child {
      line-height: 1;
      font-size: 88px;
      margin-top: 16px;
    }
  } ;
`;

const MainTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopTitle = styled(H1)<{ $showCursor: boolean }>`
  position: relative;
  font-size: 48px;
  color: ${(props) => props.theme.colors.grey900};
  line-height: 1;
  padding-right: 5px;
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: black;
  }

  ${(props) => {
    if (props.$showCursor) {
      return css`
        ::after {
          display: none;
        }
      `;
    }
    return css``;
  }}
`;

const SubTitleWrapper = styled.div`
  margin-top: 80px;
  /* margin-bottom: 80px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  ${P1} {
    line-height: 1.6;
    text-align: center;
  }
`;

const MainTitle = () => {
  const topTitle = '고객이 바라보는 시선';
  const [showCursor, setShowCursor] = useState(false);
  const textEl = useRef<HTMLParagraphElement>(null);

  const resetTyping = (element: HTMLParagraphElement) => {
    element.textContent = '';
    const strTitleArr = topTitle.split('');

    typingFunc(strTitleArr, element);
  };

  const typingFunc = (arr: string[], element: HTMLParagraphElement) => {
    if (arr.length > 0) {
      const data_temp = arr.shift();
      if (data_temp) {
        element.textContent += data_temp;
        setTimeout(() => {
          typingFunc(arr, element);
        }, 80);
      }
    } else {
      setTimeout(() => {
        resetTyping(element);
      }, 3000);
    }
  };

  useEffect(() => {
    const { current } = textEl;
    const strTitleArr = topTitle.split('');

    setInterval(() => {
      setShowCursor((prev) => {
        return !prev;
      });
    }, 500);

    if (current) {
      typingFunc(strTitleArr, current);
    }
  }, [textEl]);

  return (
    <Copmponent>
      <MainTitleWrapper>
        <TopTitle ref={textEl} $showCursor={showCursor} />
        <H1>VODA</H1>
      </MainTitleWrapper>
      <SubTitleWrapper>
        <P1>
          고객의 시선으로 웹 서비스 사용 패턴 분석을 통해 <br />
          사이트 효율성을 연구하고, 고객이 원하는 정보를 <br />
          빠르고 명확하게 제공할 수 있는 인사이트를 제시합니다.
        </P1>
      </SubTitleWrapper>
      {/* <MoreButton
        isBold
        isBackGround
        isExternal
        to="http://analytics-demo.nerdfactory.ai"
        title={'VODA 체험해보기'}
      /> */}
    </Copmponent>
  );
};

export default MainTitle;
