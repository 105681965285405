import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

import RootTemplate from 'components/Templates/RootTemplate';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface IndexPageProps {
  data: {
    mainImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    heatmapImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    lotateImg3: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => {
  return (
    <Component>
      <Helmet>
        <title>VODA</title>
        <meta
          name="description"
          content="VODA는 당신의 특성에 맞춰 사용자를 분석하고, 당신의 목표가 이루어지도록 정보를 이끌어내는 사용자 경험 통합 데이터 분석 솔루션입니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content="VODA" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="<https://voda.nerdfactory.ai/>" />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta
          property="og:description"
          content="VODA는 당신의 특성에 맞춰 사용자를 분석하고, 당신의 목표가 이루어지도록 정보를 이끌어내는 사용자 경험 통합 데이터 분석 솔루션입니다"
        />
        <meta property="og:site_name" content="VODA" />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>

      <RootTemplate
        lotateImg={[
          data.lotateImg1.childImageSharp.fluid,
          data.lotateImg2.childImageSharp.fluid,
          data.lotateImg3.childImageSharp.fluid,
        ]}
        logoNerdImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        functionData={[
          {
            IllustImg: data.mainImg.childImageSharp.fluid,
            descData: {
              title: '고객이 원하는 것의 시각화',
              desc: '웹 사이트를 사용하는 고객의\\n포괄적인 니즈 분석\\n많은 데이터를 바라볼 수는 있지만,\\n"고객이 왜?" 라는 질문에 명확한\\n답변이 있었을까요?\\n　\\nVODA는 웹 사이트 특성에 맞춰\\n고객을 분석하고,\\n서비스의 목표를 달성할 수 있는 가장\\n효율적인 방법을 제시합니다.',
            },
            isBackGround: false,
            isIllustBackground: true,
            isDescLeft: true,
            isScroll: true,
            width: 700,
            key: '0',
          },
          {
            IllustImg: data.heatmapImg.childImageSharp.fluid,
            descData: {
              title: '고객을 이해할 수 있는 인사이트',
              desc: '어떤 흐름으로 서비스를 이용할까요?\\n고객이 서비스의 어느 부분에 관심이\\n많을까요?\\n더이상 고객의 행동을 추측하지\\n마세요.\\n　\\nVODA가 서비스를 사용하는 고객을\\n보다 더 잘 이해할 수 있도록\\n도와드립니다.',
              buttonTitle: 'VODA기능 더 알아보기',
              to: 'functions/uxAnalysis',
            },
            isBackGround: false,
            isIllustBackground: false,
            isDescLeft: false,
            isScroll: false,
            width: 700,
            key: '2',
          },
        ]}
      />
    </Component>
  );
};

export default IndexPage;

export const queryData = graphql`
  query {
    mainImg: file(name: { eq: "img-main" }) {
      childImageSharp {
        fluid(maxWidth: 852, fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heatmapImg: file(name: { eq: "img-main-ux" }) {
      childImageSharp {
        fluid(maxWidth: 912, maxHeight: 929, fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg1: file(name: { eq: "main_illust___2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg2: file(name: { eq: "main_illust___3" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lotateImg3: file(name: { eq: "img_main_illust___4" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
